.App-header {
  background-color: white; /* Set background to white to match the style */
  padding: 10px 20px; /* Adjust padding to reduce space */
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #666666; /* Change the color to match the gray text */
  font-family: 'Arial', sans-serif;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.large-text {
  font-size: 20px; /* Adjust the size as needed */
}

.App-header h1 {
  margin: 0;
  font-size: 2em;
}

.nav-links {
  display: flex;
  gap: 40px; /* Increase spacing between links */
}

.nav-button {
  color: #666; /* Set color to gray */
  font-size: 1.2em; /* Increase font size */
  font-weight: 500; /* Adjust font weight */
  text-decoration: none;
  transition: color 0.3s ease;
}

.nav-button:hover {
  color: black; /* Change color on hover */
}

.App-main {
  flex: 0 1 auto; /* Prevent flexbox from expanding .App-main */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Align content to the top */
  padding: 10px 20px; /* Adjust or reduce padding */
  margin: 10px; /* Remove any margin */
  /* max-height: 900px; Set a maximum height if necessary */
  /* max-width: 800px; */
  padding-top: 20px; /* Add padding to the top */
}

.content-container {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 100%; /* Ensure it takes the full available width */
  padding: 10px;
  box-sizing: border-box; /* Include padding in the width calculation */
}

.support-us{
  min-height: 400px;
  max-width: 800px; /* Set a maximum width for the content */
  margin: 0 auto; /* Center the content horizontally */
  padding: 20px; /* Add padding around the content */
  font-family: 'Helvetica', serif; /* Use a professional serif font */
  line-height: 1.7; /* Improve readability with a comfortable line height */
  color: #333; /* Set a professional, easy-to-read text color */
  align-self: start;
}

.support-us h2 {
  margin: 0;
  font-size: 2em;
}

.getting-started-content {
  max-width: 800px; /* Set a maximum width for the content */
  margin: 0 auto; /* Center the content horizontally */
  padding: 20px; /* Add padding around the content */
  font-family: 'Helvetica', serif; /* Use a professional serif font */
  line-height: 1.7; /* Improve readability with a comfortable line height */
  color: #333; /* Set a professional, easy-to-read text color */
  border-radius: 8px; /* Add subtle rounded corners for a modern look */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a slight shadow for depth */
}

.getting-started-content h1,
.getting-started-content h2,
.getting-started-content h3 {
  color: #222; /* Darker color for headings to make them stand out */
  margin-bottom: 20px; /* Space out headings for better structure */
  font-weight: 700; /* Bold headings for emphasis */
}

.getting-started-content p {
  margin-bottom: 20px; /* Space out paragraphs */
  font-size: 18px; /* Set a comfortable font size */
}

.getting-started-content a {
  color: #007bff; /* Use a professional blue for links */
  text-decoration: none; /* Remove underlines for a cleaner look */
  font-weight: 600; /* Make links stand out slightly */
}

.getting-started-content a:hover {
  text-decoration: underline; /* Add underline on hover for clarity */
}

.getting-started-content ol {
  padding-left: 20px; /* Indent ordered lists */
  margin-bottom: 20px; /* Space out lists from other content */
}

.getting-started-content ol li {
  margin-bottom: 10px; /* Space out list items */
  font-size: 18px; /* Consistent font size with paragraphs */
}

.getting-started-content ul {
  padding-left: 20px; /* Indent unordered lists */
  margin-bottom: 20px; /* Space out lists from other content */
}

.getting-started-content ul li {
  margin-bottom: 10px; /* Space out list items */
  font-size: 18px; /* Consistent font size with paragraphs */
}

.word-grid {
  display: grid;
  grid-template-columns: repeat(10, 1fr); /* 10 columns */
  grid-template-rows: repeat(12, 1fr);    /* 12 rows */
  grid-gap: 5px;
  width: 100%;
  max-width: 1000px; /* Adjust the max-width to fit within the available space */
  overflow: hidden;
  border: 2px solid #282c34;
  padding: 10px;
  background-color: #f9f9f9;
}

.word-cell {
  background-color: #6df7ad;
  /* background-color: #61dafb; */
  padding: 5px;
  border-radius: 3px;
  text-align: center;
  font-size: 12px;
  color: #282c34;
  transition: transform 0.3s ease, background-color 0.3s ease;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.word-cell:hover {
  transform: scale(1.1);
  background-color: #f57674;
}

.hidden-cell {
  background-color: #f57674;
  color: white;
  font-weight: bold;
}

.button-group {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.App-main button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #9ec9d5;
  border: none;
  border-radius: 5px;
  color: #282c34;
  transition: background-color 0.3s ease;
}

.App-main button:hover {
  background-color: #21a1f1;
}

.support-us {
  margin-top: 20px;
  padding: 0;
  text-align: center;
  max-width: 600px;
}

.support-us h2 {
  margin: 0 0 10px;
  font-size: 16px;
  text-transform: uppercase;
}

.donation-line {
  display: flex;
  justify-content: center;
  font-size: 12px;
  margin-bottom: 10px;
  flex-wrap: wrap;
  gap: 10px;
}

.donation-line span {
  margin: 5px 0;
  position: relative;
  display: flex;
  align-items: center;
}

.copy-icon {
  margin-left: 5px;
  cursor: pointer;
  transition: color 0.3s ease;
}

.copy-icon:hover {
  color: #5c5e5f;
}

.copy-icon:hover::after {
  content: 'Copy to Clipboard';
  position: absolute;
  bottom: -20px;
  left: 0;
  width: 100px;
  background-color: #333;
  color: white;
  font-size: 10px;
  text-align: center;
  border-radius: 3px;
  padding: 2px;
}

.App-footer {
  background-color: #333;
  padding: 20px;
  text-align: center;
  color: white;
  font-family: 'Arial', sans-serif;
  letter-spacing: 1px;
}

.App-footer a {
  color: #f9fbfc;
  text-decoration: none;
}

.App-footer a:hover {
  text-decoration: underline;
}